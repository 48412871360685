.main-container {
  height: 100vh;
  overflow: auto;
}

.top-banner {
  position: absolute;
  height: 56px;
  left: 84px;
  right: 0%;
  top: 0px;
  /* EverestHeaderBG */
  background: linear-gradient(269.69deg, #4a4e8e -0.65%, #9499f1 118.65%);
  z-index: 10;
}

.logoImage{
  position: absolute;
  width: 84px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 56px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #636799;
  border-image-source: linear-gradient(0deg, #636799, #636799),
    linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  border: none;
  z-index: 10;
}

.arrow {
  position: absolute;
  width: 84px;
  left: 0px;
  top: 56px;
  height: 32px;
  bottom: 0px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #636799;
  border-image-source: linear-gradient(0deg, #636799, #636799),
    linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  border: none;
  z-index: 10;
}

.menu-expand {
  overflow: hidden;
  width: 84px;
  height: 100vh;
  transition: width 400ms ease-out, height 600ms ease-out;
}

.menu-collapsed {
  width : 0px;
  height: 0px;
  transition: width 400ms ease-in, height 600ms ease-in;
}

.render-wrapper {
  padding-top: 56px;
  height: inherit;
  position: relative;
  overflow: hidden;
}

.left-section {
  position: absolute;
  left: 0px;
  top: 88px;
  bottom: 0px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #636799;
  border: 1px solid #dadada;
  border-image-source: linear-gradient(0deg, #636799, #636799),
    linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  border-top: none;
  z-index: 10;
}

.right-wrapper {
  padding: 20px 30px;
  height: calc(100vh - 56px);
  padding-top: 52px;
  overflow: auto;
}

.right-wrapper-expand {
  margin-left: 84px;
  transition: margin-left 400ms ease-out;
}

.right-wrapper-collapse {
  margin-left: 0px;
  transition: margin-left 400ms ease-in;
}
.breadcrumb-section {
  position: absolute;
  height: 32px;
  left: 0px;
  right: 0px;
  margin-left: 84px;
  background: #e8e8e8;
  z-index: 10;
}

.nav-links a {
  display: block;
  text-decoration: none;
  margin: 4px;
  margin-top: 0;
  border-radius: 4px;
  padding: 9px 9px;
}

.nav-links a:hover,
.active {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}
.nav-links span {
  /* display: block;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  color: #1c1c1c;
  font-style: normal;
  font-weight: normal;
  padding-top: 5px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  justify-content: center;
  color: #1c1c1c;
  margin: 0px !important;
  padding-top: 7px !important;
  text-align: center;
}

.nav-links img {
  /* height: 22px;
  width: 25px; */
  display: block;
  margin: 0 auto;
  min-height: 22px;
}

.location-name {
  font-family: WorkSans-Regular;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: 18px;
}
.current-day-time-text {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #636799;
  border-radius: 4px;
  font-family: WorkSans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px;
}
.member-options {
  list-style: none;
  display: flex;
}
.e-avatar.e-avatar-circle {
  border-radius: 50%;
}

.header-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 84px;
  height: inherit;
  padding-right: 30px;
}
.header-part h3 {
  font-family: WorkSans;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  padding: 0 50px;
  color: #ffffff;
}
.right-header {
  display: flex;
  align-items: center;
}
.img-user-help {
  display: flex;
  align-items: center;
}
.img-user-help img {
  font-size: 12px;
  padding: 11px;
  /* margin-left: 10px; */
}
.img-user {
  height: 55px;
}
.ant-dropdown-menu {
  position: relative;
  /* margin: 0; */
  margin-top: 16px !important;
}
.submenu-option-icon {
  padding-right: 30px;
}

.nav-links {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 92px);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .nav-links {
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-clip: text;
    /* uncomment following line to see the trick in actions */
    /* -webkit-text-fill-color: transparent;
     transition: background-color .8s; */
  }
  .nav-links:hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
  .nav-links::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .nav-links::-webkit-scrollbar-track {
    display: none;
  }
  .nav-links::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: inherit;
  }
}

.additional-links {
  /* padding: 10px 0; */
  /* border-top: 1px solid #CCCCCC; */
  /* border-width: 1px; */
}

.additional-links .clientLogo {
  margin-top: 30px;
  margin-bottom: 10px;
}
