@font-face {
  font-family: WorkSans-Bold;
  src: url("./fonts/WorkSans-Bold.ttf") format("trueType");
}
@font-face {
  font-family: WorkSans-Regular;
  src: url("./fonts/WorkSans-Regular.ttf") format("trueType");
}
@font-face {
  font-family: WorkSans-Light;
  src: url("./fonts/WorkSans-Light.ttf") format("trueType");
}
@font-face {
  font-family: WorkSans-Medium;
  src: url("./fonts/WorkSans-Medium.ttf") format("trueType");
}

@font-face {
  font-family: Rubik;
  src: url("./fonts/Rubik-VariableFont_wght.ttf") format("trueType");
}

@font-face {
  font-family: WorkSans;
  src: url("./fonts/WorkSans-VariableFont_wght.ttf") format("trueType");
}
