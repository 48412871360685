.POModifySummaryWidth {
    width: 38%;
    float:left;
}

.POModifySummarybuttonsTab {
    width: 62%;
    float: right;
}
.POlinesModifySummarywidth {
    width: 78%;
}

.polineModifySummaryButtonswidth {
    width: 22%;
}
.POModifySummaryarrowVisibleButton{
    border: 1px solid #737ae6 !important;
    color: #737ae6 !important;
    margin-top: 0px;
    border-radius: 4px;
     height: 36px;
    font-family: Rubik;
    font-weight: 600;
    background: transparent;  
}
.POModifySummaryNotesButton{
    border: transparent !important;
    color: white !important;
    margin-top: 0px;
    border-radius: 4px;
     height: 36px;
    font-family: Rubik;
    font-weight: 600;
    background: transparent;  
}
.POModifySummaryPlainButtons {
    border-color: #737ae6 !important;
    color: #737ae6 !important;
    margin-top: 0px;
    border-radius: 4px;
    margin-left: 12px;
    height:36px;
    font-family: Rubik;
    font-weight: 600;
}
.POModifySummaryPlainButtons:hover{
    background-color:white !important;
}
.POModifySummaryCreatePoButton {
    border-color: #737ae6 !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #737AE6 !important; 
    color: #fff !important;
    margin-top: 0px;
    margin-left: 0px;
    border-radius: 4px;
    float:right;
    height:36px;
    font-size: 14px;
    font-family: Rubik;
    font-weight:600;
}
.POModifySummaryHeaderwidth {
    width: 100%;
    font-family: Work Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0.0015em;
    text-align: left;
}
.POModifySummaryTabStyle1 {
    width: 60%;
    float:left;
    padding: initial;
}

.POModifySummaryTabStyle2 {
    width: 40%;
    padding: initial;
float:left;

}
.POModifySummaryVendorTabStyle1 {
    width: 50%;
    float:left;
    padding: initial;
}

.POModifySummaryVendorTabStyle2 {
    width: 50%;
    padding: initial;
float:left;

}
.POModifySummarytransConfirmCheckBox{
    height: 10px;
left: 13px;
top: 19px;
border-radius: 1px;

}
.POModifySummaryHeaderfields .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(33, 33, 33, 0.06);
    border-radius: 6px;
    outline: none;
    font-weight: 400;
    height: 36px;
    align-items: center;
}

.POModifySummaryHeaderfields1 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(33, 33, 33, 0.06);
    border-radius: 6px;
    outline: none;
    font-weight: 400;
    height: 36px;
    align-items: center;
    color: black;


}
.POModifySummaryChargesButton{
    font-family: Rubik;
    color:#737ae6 !important;
    border: none;
    background-color: transparent;
    float: left;
    margin-top:5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration-line: underline;

}
.POModifySummaryTabsStyle {
    background-color: #F2F2F2;
    width: 100%;
    border-radius: 2px;
    height:50px;
}
.POModifyCopyLabel{
    font-family: Rubik;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    margin-top:10px;
    margin-left:20px;
    letter-spacing: 0.035em;
    text-align: left;
}

.POCopyTabStyle {
    width: 40%;
    float:right;
    padding: initial;
    margin-top:10px;
}
.POCopyLabelTabStyle {
    width: 100%;
    float:right;
    padding: initial;
    margin-top:10px;

}
.modifyPOSummaryFormLabel{
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    
    }
    .modifyPOSummaryFormlabelButton{
        font-family: Rubik;
        color:#737ae6 !important;
        border: none;
        background-color: transparent;
        float: left;
        margin-top:5px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        text-decoration-line: underline;
    
    }