.loadManagerTitleWidth {
    width: 70%;
}

.loadManagerButtonsWidth {
    width: 29%;
    padding-top: 1.7rem;
    text-align: end;
}

.logisticLMDeleteButton {
    color: #8C4D3F !important;
    border: 1px solid #8C4D3F !important;
    height: 36px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.0125em;
    text-align: center;
}

.logisticLMDeleteButton:hover {
    background-color: white !important;
}

.logisticLMButton {
    border-color: #737ae6 !important;
    color: #737ae6 !important;
    margin-top: 0px;
    background-color: white !important;
    border-radius: 4px;
    margin-left: 12px;
    height: 36px;
    font-family: Rubik;
    font-weight: 600;
}

.logisticLMButton:hover {
    background-color: white !important;
}

.logisticLMSaveButton {
    border-color: #737ae6 !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #737AE6 !important;
    color: #fff !important;
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 4px;
    /* float: right; */
    height: 36px;
    font-size: 14px;
    font-family: Rubik;
    font-weight: 600;
}

.logisticLMSearchbarwidth {
    width: 100%;
    font-family: Work Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0.0015em;
    text-align: left;
}

.logisticLMArrowVisibleButton {
    border: 1px solid #737ae6 !important;
    color: #737ae6 !important;
    margin-top: 0px;
    border-radius: 4px;
    margin-right: 3px;
    height: 36px;
    font-family: Rubik;
    font-weight: 600;
    margin-top: 0px;
    float: right;
    background: transparent;
}

.logisticLMNotesImage {
    height: 30px;
    width: 30px;
    margin-left: 5px;
}

.logisticLMSearchFieldsTabsStyle {
    /* background-color: #F2F2F2;
    width: 100%;
    border-radius: 2px; */
}

.logisticLMHeaderfields .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(33, 33, 33, 0.06);
    border-radius: 6px;
    outline: none;
    font-weight: 400;
    height: 36px;
    align-items: center;
}

.logisticLMHeaderfields1 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(33, 33, 33, 0.06);
    border-radius: 6px;
    outline: none;
    font-weight: 400;
    height: 36px;
    align-items: center;
    color: black;


}

.logisticLMSearchbutton {
    border-color: #737ae6 !important;
    color: #737ae6 !important;
    margin-top: 0px;
    border-radius: 4px;
    margin-left: 10px;
    height: 36px;
    width: 130px;
    font-family: Rubik;
    font-weight: 600;
    margin-top: 35px;


}

.logisticLMSearchbutton:hover {
    background-color: #F2F2F2 !important;
}


.logisticLMRemoveYesButton {
    border-color: #737ae6 !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        #737ae6 !important;
    color: #fff !important;
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 4px;
    margin-top: 20px;
    height: 36px;
    width: 100px;
    float: right;
    font-size: 14px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
}

.logisticLMRemoveLabel {
    color: #1c1c1c;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0015em;
    margin-bottom: 10px;
}

.logisticLMRemoveLabelMoreData {
    color: #1c1c1c;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0015em;
    margin-bottom: 10px;
    width: 23rem;
    overflow-x: scroll;
}

.logisticLMRemoveCancelButton {
    font-family: Rubik;
    color: #737ae6 !important;
    border: none;
    background-color: transparent;
    float: left;
    margin-top: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
}

#loadManager .e-pager {
    background-color: #f2f2f2 !important;
}

#loadManager .e-pagercontainer {
    background-color: #f2f2f2 !important;
}

.newLaodHeaderLabelWidth {
    width: 15%;
}

.newLoadBodyDiv {
    margin-top: 25px;
    width: 100%;
}

.logisticNewLoadButton {
    border: 1px solid #737ae6 !important;
    color: #737ae6 !important;
    margin-top: 20px;
    background-color: white !important;
    border-radius: 4px;
    margin-left: 25px;
    height: 36px;
    font-family: Rubik;
    font-weight: 600;
}

.logisticNewLoadButton:hover {
    background-color: white !important;
}

.loadManagerTableLoadPO {
    font-family: "WorkSans";
    color: #737ae6 !important;
    border: none;
    background-color: transparent;
    /* float: left; */
    margin-top: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration-line: underline;

}

.loadManagerTablelabelButton {
    font-family: "WorkSans";
    color: #737ae6 !important;
    border: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    cursor: pointer;
    padding-left: 0;

}

.loadManagerTablelabelButton a {
    color: #737ae6 !important;
}

.loadManagerTablelabelButtonMainTable {
    font-family: "WorkSans";
    color: #737ae6 !important;
    border: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    white-space: nowrap;
    /* width: 60px; */
    /* overflow: hidden; */
    text-overflow: ellipsis;
    padding: 0;

}

.loadManagerTableNotesButton {
    font-family: Rubik;
    color: #737ae6 !important;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.loadManagerMainGrid .e-detailcell {
    padding: 0px !important;
}

.loadManagerCarrierShippingGrid .e-detailcell {
    padding: 0px !important;
}

#poLinesCarrier .e-table {
    background-color: white !important;
}

#poLinesCarrier .e-headercell {
    background: white;
    /* color: #070000; */
    height: 30px;
}

#poLinesCarrier .e-headertext {
    font-size: 10px !important;
    /* font-weight: 700; */
}

#subtask .e-columnheader {
    display: none;
}

#subtask .e-row {
    background: white;
}

.loadManagerMainGrid .e-row {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
        linear-gradient(0deg, #79C24F, #79C24F);
}

.loadManagerCarrierShippingGrid .e-row {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
        linear-gradient(0deg, #79C24F, #79C24F);
}

#poLinesCarrier .e-row {
    background: white !important;
}

.tdArrowClass {
    background: white !important;
}

.uaText {
    color: red;
    font-size: 12px !important;
}

.btnTender {
    background: #4f527a;
    color: white;
    border-radius: 3px;
    border-color: #4f527a;
    padding: 0.5rem;
}

.btnTenderLoadView {
    background: #4f527a;
    color: white !important;
    border-radius: 3px;
    border-color: #4f527a !important;
    font-size: 13px;
    height: 24px;
}

.btnTenderLoadView:hover {
    background: #4f527a;
    color: white !important;
    border-radius: 3px;
    border-color: #4f527a !important;
    font-size: 13px;
    height: 24px;
}

.recveGrid {
    width: 98%;
    /* margin-top: 1rem; */
}

.cancelAndAutoFButtons {
    margin-left: 40rem;
}

.cancelAndAutoFButtonsLoadAutoFreight {
    margin-left: 27rem;
}

.cancelClearAndAutoFButtons {
    margin-left: 22rem;

}

.cancelClearAndAutoFreightButtons {
    margin-left: 46rem;
}

.addLoadAutoFButtons {
    margin-left: 27rem;
}

.addLoadCancelButton {
    padding: 0.5rem;
    width: 7rem;
    border: 1px solid #5c62b8;
    color: #5c62b8;
    margin-left: 0.5rem;
    font-weight: bold;
    background-color: white;
}

.clearContentsButton {
    padding: 0.5rem;
    width: 10rem;
    border: 1px solid #5c62b8;
    color: #5c62b8;
    margin-left: 0.5rem;
    font-weight: bold;
    background-color: white;
}

.saveBtnAddLoad {
    position: relative;
    top: 2.2rem;
}

.loaderClass {
    height: 5rem;
}

.loaderClassInItemDetails {
    height: 6rem;
    width: 17rem;
    margin-left: 21rem;
}

.LoadImg {
    text-align: center;
}

.btnAuditInfoClose {
    margin-left: 64rem;
    margin-top: 1rem;
    background: #5c62b8;
    color: #fff;
    width: 8rem;
    height: 2rem;
}

.btnAuditInfoClose:hover {
    background: #5c62b8;
    color: #fff;
    cursor: pointer;
}

.itemDescPOLinesTd {
    white-space: nowrap;
    width: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

.poLinesTblTd {
    font-size: 12px;
    float: right;
    padding-right: 0.5rem;
    white-space: nowrap;
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* @media screen and (min-device-width: 768px) and (max-device-width: 1024px){
        td.tdOne{
            width: 3.7rem !important;
        }
        td.tdTwo{
            width: 6.8rem !important;
        }
} */


/* @media only screen and (min-width: 960px)  */
@media only screen and (min-width: 1420px) and (max-width: 1600px) {

    /* 90 & 100 */
    td.tdOne {
        width: 3.5rem !important;
    }

    td.tdTwo {
        width: 6.3rem !important;
    }

    td.tdThree {
        width: 8.1rem !important;
    }

    td.tdFour {
        width: 5.93rem !important;
    }

    td.tdFive {
        width: 5.7rem !important;
        font-weight: 500;
    }

    td.tdSix {
        width: 4.94rem !important;
        font-weight: 500;
    }

    td.tdSeven {
        width: 5.92rem !important;
        font-weight: 500;
    }

    td.tdEight {
        width: 5.65rem !important;
        font-weight: 500;
    }

    td.tdNine {
        width: 5.3rem !important;
    }

    td.tdTen {
        width: 5.35rem !important;
    }

    td.tdLeven {
        width: 5.3rem !important;
    }

    td.tdTwelve {
        width: 3.75rem !important;
    }

    td.tdThirteen {
        width: 3.7rem !important;
    }

    td.tdFourteen {
        width: 3.77rem !important;
    }

    td.tdFifteen {
        width: 4.1rem !important;
    }

    td.tdSixteen {
        width: 3.7rem !important;
    }
}

@media only screen and (min-width: 1024px) {

    /* 90 & 100 */
    td.tdOne {
        width: 3.5rem !important;
    }

    td.tdTwo {
        width: 6.3rem !important;
    }

    td.tdThree {
        width: 8.1rem !important;
    }

    td.tdFour {
        width: 5.93rem !important;
    }

    td.tdFive {
        width: 5.7rem !important;
        font-weight: 500;
    }

    td.tdSix {
        width: 4.94rem !important;
        font-weight: 500;
    }

    td.tdSeven {
        width: 5.92rem !important;
        font-weight: 500;
    }

    td.tdEight {
        width: 5.65rem !important;
        font-weight: 500;
    }

    td.tdNine {
        width: 5.3rem !important;
    }

    td.tdTen {
        width: 5.35rem !important;
    }

    td.tdLeven {
        width: 5.3rem !important;
    }

    td.tdTwelve {
        width: 3.75rem !important;
    }

    td.tdThirteen {
        width: 3.7rem !important;
    }

    td.tdFourteen {
        width: 3.77rem !important;
    }

    td.tdFifteen {
        width: 4.1rem !important;
    }

    td.tdSixteen {
        width: 3.7rem !important;
    }
}

@media only screen and (min-width: 1706px) {

    /* 75 */
    td.tdOne {
        width: 3.9rem !important;
    }

    td.tdTwo {
        width: 6.8rem !important;
    }

    td.tdThree {
        width: 8.9rem !important;
    }

    td.tdFour {
        width: 6.5rem !important;
    }

    td.tdFive {
        width: 6.1rem !important;
        font-weight: 500;
    }

    td.tdSix {
        width: 5.5rem !important;
        font-weight: 500;
    }

    td.tdSeven {
        width: 6.4rem !important;
        font-weight: 500;
    }

    td.tdEight {
        width: 6.2rem !important;
        font-weight: 500;
    }

    td.tdNine {
        width: 5.85rem !important;
    }

    td.tdTen {
        width: 5.8rem !important;
    }

    td.tdLeven {
        width: 5.8rem !important;
    }

    td.tdTwelve {
        width: 4rem !important;
    }

    td.tdThirteen {
        width: 4.2rem !important;
    }

    td.tdFourteen {
        width: 4.2rem !important;
    }

    td.tdFifteen {
        width: 4.5rem !important;
    }

    td.tdSixteen {
        width: 4.1rem !important;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1705px) {

    /* 80 */
    td.tdOne {
        width: 3.6rem !important;
    }

    td.tdTwo {
        width: 6.3rem !important;
    }

    td.tdThree {
        width: 8.35rem !important;
    }

    td.tdFour {
        width: 6rem !important;
    }

    td.tdFive {
        width: 5.8rem !important;
        font-weight: 500;
    }

    td.tdSix {
        width: 5.04rem !important;
        font-weight: 500;
    }

    td.tdSeven {
        width: 6.1rem !important;
        font-weight: 500;
    }

    td.tdEight {
        width: 5.6rem !important;
        font-weight: 500;
    }

    td.tdNine {
        width: 5.4rem !important;
    }

    td.tdTen {
        width: 5.48rem !important;
    }

    td.tdLeven {
        width: 5.5rem !important;
    }

    td.tdTwelve {
        width: 3.9rem !important;
    }

    td.tdThirteen {
        width: 3.7rem !important;
    }

    td.tdFourteen {
        width: 3.7rem !important;
    }

    td.tdFifteen {
        width: 4.2rem !important;
    }

    td.tdSixteen {
        width: 3.9rem !important;
    }
}

@media only screen and (min-width: 1707px)and (max-width: 1920px) {
    td.tdOne {
        width: 4.5rem !important;
    }

    td.tdTwo {
        width: 7.7rem !important;
    }

    td.tdThree {
        width: 10.2rem !important;
    }

    td.tdFour {
        width: 7.4rem !important;
    }

    td.tdFive {
        width: 7.1rem !important;
        font-weight: 500;
    }

    td.tdSix {
        width: 6.2rem !important;
        font-weight: 500;
    }

    td.tdSeven {
        width: 7.4rem !important;
        font-weight: 500;
    }

    td.tdEight {
        width: 7.1rem !important;
        font-weight: 500;
    }

    td.tdNine {
        width: 6.6rem !important;
    }

    td.tdTen {
        width: 6.7rem !important;
    }

    td.tdLeven {
        width: 6.63rem !important;
    }

    td.tdTwelve {
        width: 4.7rem !important;
    }

    td.tdThirteen {
        width: 4.7rem !important;
    }

    td.tdFourteen {
        width: 4.6rem !important;
    }

    td.tdFifteen {
        width: 5.1rem !important;
    }

    td.tdSixteen {
        width: 4.7rem !important;
    }
}

@media only screen and (min-width: 1921px)and (max-width: 2560px) {
    td.tdOne {
        width: 6.1rem !important;
    }

    td.tdTwo {
        width: 10.65rem !important;
    }

    td.tdThree {
        width: 13.9rem !important;
    }

    td.tdFour {
        width: 10.1rem !important;
    }

    td.tdFive {
        width: 9.6rem !important;
        font-weight: 500;
    }

    td.tdSix {
        width: 8.55rem !important;
        font-weight: 500;
    }

    td.tdSeven {
        width: 10.2rem !important;
        font-weight: 500;
    }

    td.tdEight {
        width: 9.5rem !important;
        font-weight: 500;
    }

    td.tdNine {
        width: 9.1rem !important;
    }

    td.tdTen {
        width: 9rem !important;
    }

    td.tdLeven {
        width: 9.2rem !important;
    }

    td.tdTwelve {
        width: 6.5rem !important;
    }

    td.tdThirteen {
        width: 6.2rem !important;
    }

    td.tdFourteen {
        width: 6.4rem !important;
    }

    td.tdFifteen {
        width: 6.9rem !important;
    }

    td.tdSixteen {
        width: 6.4rem !important;
    }
}

@media only screen and (min-width: 1420px) and (max-width: 1600px) {

    /* 90 & 100 */
    td.tdOneCarrier {
        width: 3.5rem !important;
    }

    td.tdTwoCarrier {
        width: 6.3rem !important;
    }

    td.tdThreeCarrier {
        width: 8.1rem !important;
    }

    td.tdFourCarrier {
        width: 5.93rem !important;
    }

    td.tdFiveCarrier {
        width: 5.7rem !important;
        font-weight: 500;
    }

    td.tdSixCarrier {
        width: 4.94rem !important;
        font-weight: 500;
    }

    td.tdSevenCarrier {
        width: 5.92rem !important;
        font-weight: 500;
    }

    td.tdEightCarrier {
        width: 5.65rem !important;
        font-weight: 500;
    }

    td.tdNineCarrier {
        width: 5.3rem !important;
    }

    td.tdTenCarrier {
        width: 5.35rem !important;
    }

    td.tdLevenCarrier {
        width: 5.3rem !important;
    }

    td.tdTwelveCarrier {
        width: 3.75rem !important;
    }

    td.tdThirteenCarrier {
        width: 3.7rem !important;
    }

    td.tdFourteenCarrier {
        width: 3.77rem !important;
    }

    td.tdFifteenCarrier {
        width: 4.1rem !important;
    }

    td.tdSixteenCarrier {
        width: 3.7rem !important;
    }
}

@media only screen and (min-width: 1024px) {

    /* 90 & 100 */
    td.tdOneCarrier {
        width: 3.5rem !important;
    }

    td.tdTwoCarrier {
        width: 3.9rem !important;
    }

    td.tdThreeCarrier {
        width: 6.8rem !important;
    }

    td.tdFourCarrier {
        width: 9rem !important;
    }

    td.tdFiveCarrier {
        width: 6.5rem !important;
        font-weight: 500;
    }

    td.tdSixCarrier {
        width: 6.2rem !important;
        font-weight: 500;
    }

    td.tdSevenCarrier {
        width: 5.5rem !important;
        font-weight: 500;
    }

    td.tdEightCarrier {
        width: 6.2rem !important;
        font-weight: 500;
    }

    td.tdNineCarrier {
        width: 5.9rem !important;
    }

    td.tdTenCarrier {
        width: 4.1rem !important;
    }

    td.tdLevenCarrier {
        width: 4.1rem !important;
    }

    td.tdTwelveCarrier {
        width: 4.2rem !important;
    }

    td.tdThirteenCarrier {
        width: 4.4rem !important;
    }

    td.tdFourteenCarrier {
        width: 4.2rem !important;
    }

    td.tdFifteenCarrier {
        width: 4.1rem !important;
    }

    td.tdSixteenCarrier {
        width: 3.7rem !important;
    }
}

@media only screen and (min-width: 1706px) {

    /* 75 */
    td.tdOneCarrier {
        width: 3.9rem !important;
    }

    td.tdTwoCarrier {
        width: 4.8rem !important;
    }

    td.tdThreeCarrier {
        width: 8.5rem !important;
    }

    td.tdFourCarrier {
        width: 11rem !important;
    }

    td.tdFiveCarrier {
        width: 8.1rem !important;
        font-weight: 500;
    }

    td.tdSixCarrier {
        width: 7.6rem !important;
        font-weight: 500;
    }

    td.tdSevenCarrier {
        width: 6.8rem !important;
        font-weight: 500;
    }

    td.tdEightCarrier {
        width: 7.6rem !important;
        font-weight: 500;
    }

    td.tdNineCarrier {
        width: 7.2rem !important;
    }

    td.tdTenCarrier {
        width: 5rem !important;
    }

    td.tdLevenCarrier {
        width: 5.2rem !important;
    }

    td.tdTwelveCarrier {
        width: 5rem !important;
    }

    td.tdThirteenCarrier {
        width: 5.5rem !important;
    }

    td.tdFourteenCarrier {
        width: 5.1rem !important;
    }

    td.tdFifteenCarrier {
        width: 4.5rem !important;
    }

    td.tdSixteenCarrier {
        width: 4.1rem !important;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1705px) {

    /* 80 */
    td.tdOneCarrier {
        width: 3.6rem !important;
    }

    td.tdTwoCarrier {
        width: 4.5rem !important;
    }

    td.tdThreeCarrier {
        width: 7.9rem !important;
    }

    td.tdFourCarrier {
        width: 10.2rem !important;
    }

    td.tdFiveCarrier {
        width: 7.5rem !important;
        font-weight: 500;
    }

    td.tdSixCarrier {
        width: 7.1rem !important;
        font-weight: 500;
    }

    td.tdSevenCarrier {
        width: 6.2rem !important;
        font-weight: 500;
    }

    td.tdEightCarrier {
        width: 7.18rem !important;
        font-weight: 500;
    }

    td.tdNineCarrier {
        width: 6.7rem !important;
    }

    td.tdTenCarrier {
        width: 4.7rem !important;
    }

    td.tdLevenCarrier {
        width: 4.7rem !important;
    }

    td.tdTwelveCarrier {
        width: 4.7rem !important;
    }

    td.tdThirteenCarrier {
        width: 5.16rem !important;
    }

    td.tdFourteenCarrier {
        width: 4.8rem !important;
    }

    td.tdFifteenCarrier {
        width: 4.2rem !important;
    }

    td.tdSixteenCarrier {
        width: 3.9rem !important;
    }
}

@media only screen and (min-width: 1707px)and (max-width: 1920px) {
    td.tdOneCarrier {
        width: 4.5rem !important;
    }

    td.tdTwoCarrier {
        width: 5.5rem !important;
    }

    td.tdThreeCarrier {
        width: 9.7rem !important;
    }

    td.tdFourCarrier {
        width: 12.6rem !important;
    }

    td.tdFiveCarrier {
        width: 9.1rem !important;
        font-weight: 500;
    }

    td.tdSixCarrier {
        width: 8.8rem !important;
        font-weight: 500;
    }

    td.tdSevenCarrier {
        width: 7.7rem !important;
        font-weight: 500;
    }

    td.tdEightCarrier {
        width: 8.7rem !important;
        font-weight: 500;
    }

    td.tdNineCarrier {
        width: 8.2rem !important;
    }

    td.tdTenCarrier {
        width: 5.7rem !important;
    }

    td.tdLevenCarrier {
        width: 5.8rem !important;
    }

    td.tdTwelveCarrier {
        width: 5.8rem !important;
    }

    td.tdThirteenCarrier {
        width: 6.3rem !important;
    }

    td.tdFourteenCarrier {
        width: 5.7rem !important;
    }

    td.tdFifteenCarrier {
        width: 5.1rem !important;
    }

    td.tdSixteenCarrier {
        width: 4.7rem !important;
    }
}

@media only screen and (min-width: 1921px)and (max-width: 2560px) {
    td.tdOneCarrier {
        width: 6.1rem !important;
    }

    td.tdTwoCarrier {
        width: 7.65rem !important;
    }

    td.tdThreeCarrier {
        width: 13.1rem !important;
    }

    td.tdFourCarrier {
        width: 17.4rem !important;
    }

    td.tdFiveCarrier {
        width: 12.6rem !important;
        font-weight: 500;
    }

    td.tdSixCarrier {
        width: 12rem !important;
        font-weight: 500;
    }

    td.tdSevenCarrier {
        width: 10.5rem !important;
        font-weight: 500;
    }

    td.tdEightCarrier {
        width: 12rem !important;
        font-weight: 500;
    }

    td.tdNineCarrier {
        width: 11.2rem !important;
    }

    td.tdTenCarrier {
        width: 8rem !important;
    }

    td.tdLevenCarrier {
        width: 7.9rem !important;
    }

    td.tdTwelveCarrier {
        width: 8rem !important;
    }

    td.tdThirteenCarrier {
        width: 8.7rem !important;
    }

    td.tdFourteenCarrier {
        width: 8rem !important;
    }

    td.tdFifteenCarrier {
        width: 6.9rem !important;
    }

    td.tdSixteenCarrier {
        width: 6.4rem !important;
    }
}

.txtLabel {
    padding: 0.4rem;
    background: #e9ecef;
    border: 1px solid #d5d7d8;
    border-radius: 4px;
}

.txtLabelLoadSummary {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.loadSummaryRow {
    margin-left: 0rem;
}

h3.receivingHeading {
    border-bottom: 1px solid #bcb8b8;
    width: 100%;
}

tr.tdArrowClass td.e-detailrowcollapse {
    content-visibility: hidden;
}

tr.tdArrowClass td.e-detailrowexpand {
    content-visibility: hidden;
}

#btnCollapseId {
    display: none;
}

.poLinesWeightTd {
    float: right;
    padding-right: 0.5rem;
    font-size: 12px;
}

tr.e-emptyrow {
    display: none;
}

.loadNumField {
    width: 7rem;
    float: left;
    margin-right: 0.5rem;
}

.poNumField, .routingMethodField {
    width: 7rem;
    float: left;
    margin-right: 1.4rem;
}

.itemNumField {
    width: 7rem;
    float: left;
    margin-right: 1.4rem;
}

.dateTypeField {
    width: 8rem;
    float: left;
    margin-right: 0.5rem;
}

.seletDateField {
    width: 9rem;
    float: left;
    margin-right: 0.5rem;
}

.seletSpanField {
    width: 7rem;
    float: left;
    margin-right: 0.5rem;
}

.seletSpanValueField {
    width: 9rem;
    float: left;
    margin-top: 1.5rem;
    margin-right: 0.5rem;
}

span.nonConfir {
    color: red;
}

.boldText {
    font-weight: 600;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

tr.receiveTblTr td {
    text-align: center;
    padding-top: 0.5rem;
    font-size: 13px;
}

.arrivalDateSpan {
    cursor: pointer;
}

.inputNumFormat {
    padding: 0.3rem;
    border: 1px solid #cdcdcd !important;
    border-radius: 5px;
    background: #f2f2f2;
}

.inputNumFormat:focus {
    padding: 0.3rem;
    border: none !important;
    border-radius: 5px;
    background: #f2f2f2;
}

/* .ant-picker-input input::placeholder {
    color: #363333;
} */

.removeAddLoadNewLoadButtons {
    text-align: end;
    margin-top: 1.6rem;
}

.cubesCountLabel {
    white-space: nowrap;
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 0.7rem;
}

.inputBackground {
    background: white;
}

.fillColorLoadManager {
    background: #800080 !important;
    cursor: pointer !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.removeColorLoadManager {
    background: #fff;
}

#poLinesCarrier .e-grid .e-gridheader {
    padding: 0px !important;
}

.arrivalDateChange-model .ant-modal-body {
    padding-top: 10px;
}

.slectedPosDiv {
    border-bottom: 1px solid #baa6a6;
    margin-bottom: 1rem;
}

.notifDescriptionLoad {
    font-size: 0.84rem;
}

.load-cost-summary label {
    font-family: "Rubik" !important;
    font-size: 15px !important;
    line-height: 18px;
    display: block;
}

.load-cost-summary .item-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
}

.load-cost-summary span {
    font-family: "Rubik" !important;
    color: #1c1c1c;
    font-size: 15px !important;
    font-weight: 600 !important;
    display: block;
}

.carrierLoadGrid .e-table {
    width: 100% !important;
}

.loadMangerBuyerGrid .e-table {
    width: 100% !important;
}