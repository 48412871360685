.ant-tabs-tab {
  padding: 4px 0;
}

.ant-row.ant-form-item {
  width: 90%;
}

.form-field,
.form-field1,
.form-field-address {
  position: relative;
}
.form-field-upload .ant-form-item-explain-error {
  color: #ca3639;
  font-family: "Rubik";
  position: relative;
  bottom: 17px;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #ca3639 !important;
}
.ant-form-item-explain-error {
  color: #ca3639;
  font-family: "Rubik";
}
.form-field img {
  position: absolute;
  top: 30px;
  right: 5px;
}
.form-field-address img {
  position: absolute;
  top: 6px;
  right: 5px;
}
.form-field1 img {
  position: absolute;
  top: 30px;
  right: 90px;
}

.ant-input-password-icon,
.ant-picker-suffix {
  font-size: 18px;
  /* color:#1c1c1c */
}

.ant-input,
.ant-input-password,
.ant-picker {
  background-color: rgba(33, 33, 33, 0.06);
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  height: 36px;
}

/* .ant-select-auto-complete .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06) !important;
  border-radius: 6px !important;
  outline: none;
  font-weight: 400;
  height: 36px !important;
} */

/* .ant-select-auto-complete .ant-select-selection-search-input {
  height: 35px !important;
} */

.ant-form-item-required {
  width: 100%;
}

.ant-input-password .ant-input {
  background-color: initial;
  height: initial;
}

.ant-form-item-label,
.add-trading-partner-section label {
  padding-bottom: 2px !important;
}
.ant-form-item-label > label,
.custom-form-field,
.add-trading-partner-section label {
  font-family: "Rubik" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
}

.ant-form-item-label span {
  font-family: "Rubik" !important;
  font-weight: normal !important;
  font-size: 12px !important;
}
.ant-btn-primary {
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #737ae6, #737ae6) !important;
  border-color: #737ae6 !important;
  border-radius: 4px;
  height: 36px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff !important;
  border-color: #ff4d4f !important;
  background: #ff4d4f !important;
}

.ant-btn-default {
  color: #737ae6 !important;
  border-color: #737ae6 !important;
  border-radius: 4px;
  height: 36px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
}
.ant-btn-delete {
  color: rgb(140, 77, 63) !important;
  border-color: rgb(140, 77, 63) !important;
  border-radius: 4px;
  height: 36px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
}
/* .ant-select-dropdown {
  width: 200px !important;
} */
.ant-select-arrow {
  color: #1c1c1c;
}
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06);
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  height: 36px;
} */
