.e-grid * {
  font-size: 14px;
  /* font-family: "Rubik"; */
}

.e-grid .e-filterbarcell,
.e-grid .e-filterbarcelldisabled {
  height: 36px;
}
.e-rowcell {
  cursor: pointer;
  padding: 0px 5px !important;
  text-transform: capitalize;
}
.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff;
}

.e-columnheader .header-custom-color {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    #737ae6 !important;
  color: #1c1c1c;
  font-weight: 600;
}
.e-columnheader .header-custom-color .e-headertext {
  font-weight: 600;
}

.header-color {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    #737ae6 !important;
  color: #1c1c1c !important;
}
.e-grid .e-gridheader {
  border-top-width: 0px;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  #737ae6; */
  padding-right: 8px !important;
}
.e-grid .e-headercelldiv {
  text-align: center !important;
  text-transform: uppercase !important;
  padding: 0 !important;
  font-size: 12px !important;
  font-family: "WorkSans";
  line-height: 20px;
}
.status-active-field {
  height: 24px;
  display: block;
  line-height: 24px;
  width: 88px;
  margin: 0 auto;
  /* color:#79C24F ; */
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}

.status-inActive-field {
  height: 24px;
  display: block;
  line-height: 24px;
  width: 88px;
  margin: 0 auto;
  border-radius: 4px;
  /* color:#8c4d3f; */
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
}

.status-inProgress-field {
  height: 24px;
  display: block;
  line-height: 24px;
  width: 88px;
  margin: 0 auto;
  border-radius: 4px;
  /* color:#8c4d3f; */
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #f2ca52;
}
.e-headertext {
  font-family: "WorkSans";
  font-size: 12px;
  font-weight: 500;
  white-space: initial;
}

/* #FGrid_content_table {
  height: inherit;
}

#FGrid_content_table .e-emptyrow {
  text-align: center;
  font-size: 12px !important;
  font-family: "WorkSans";
} */
.ant-modal-close-x {
  display: none !important;
}
.ant-modal-title {
  width: 100% !important;
}
