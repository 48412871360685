body {
  margin: 0;
  font-family: WarkSans, "Rubik";
}

#root {
  height: inherit;
  overflow: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #044c74;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0px auto;
  top: 40%;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.add-mapping-form .ant-card-head {
  min-height: 30px;
  text-transform: uppercase;
}

.add-mapping-form .ant-card-head-title {
  padding: 5px 0;
}

.add-mapping-form .ant-card-body {
  padding: 15px;
}
/* .add-mapping-form  .customer-card{
  border: 1px solid #737AE6;
}

.add-mapping-form  .internal-card{
  border: 1px solid #737AE6;
} */

.add-mapping-form .customer-card .ant-card-head {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    #737ae6 !important;
  color: #1c1c1c !important;
}

.add-mapping-form .internal-card .ant-card-head {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6;
  color: #fff;
}

.custom-tbl-style .ant-input,
.custom-tbl-style .ant-input-password,
.custom-tbl-style .ant-picker,
.custom-tbl-style .ant-select-selector {
  background: none;
}

.custom-tbl-style
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: none;
  width: 100%;
}

.custom-tbl-style
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
  outline: none !important;
}

.custom-tbl-style
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 36px;
}

.po-line-desc {
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 36px;
  text-align: left;
  /* } */
}

.ant-popover {
  z-index: 999;
}

.demand-line-desc {
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 35px;
  text-align: left;
  /* } */
}

.ant-picker-disabled .ant-picker-input > input[disabled] {
  color: inherit !important;
}

/* .ant-select-disabled .ant-select-selector {
  background-color: inherit !important;
  color: inherit !important;
} */

.ant-picker-disabled .ant-picker-input > input[disabled]:hover {
  background-color: inherit !important;
}

.ant-input-disabled,
.ant-picker-disabled {
  /* background-color: inherit !important; */
  color: inherit !important;
}

.notificationTbl1 {
  overflow-y: auto;
  height: calc(100vh - 235px);
  display: block;
  margin: 0 !important;
}

.notificationTbl1 thead {
  position: sticky;
  top: 0;
  z-index: 5;
}

.stickyColumn {
  position: sticky;
  z-index: 3;
  /* color: White; */
  /* border: 1px solid white !important; */
}

.notificationTbl1 thead .stickyColumnHeader {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  top: 0;
  z-index: 10;
}
.notificationTab {
  width: auto !important;
}

.custom-error-modal-loader .ant-modal-content {
  border-radius: 50% !important;
}

.custom-error-modal-loader .ant-modal-body {
  padding: 0px !important;
}
