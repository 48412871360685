/* @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css"; */

@import "../node_modules/@syncfusion/ej2/styles/compatibility/material.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.exclusion-section .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #cccccc; */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6;
  height: 32px;
  text-align: center;
  color: #fff;
}

.exclusion-section .render-area {
  height: 300px;
  display: flex;
  /* overflow: auto; */
}

.exclusion-section .ex-left {
  border: 1px solid #cccccc;
}
.exclusion-section .render-area p {
  padding: 8px 20px;
  margin: 0;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
}
.exclusion-section .render-area .roles {
  width: 50%;
  height: inherit;
  border-right: 1px solid #cccccc;
  overflow: auto;
}

.exclusion-section {
  display: flex;
  justify-content: space-between;
}
.exclusion-section .ex-right {
  border: 1px solid #cccccc;
}

.exclusion-section .render-area span {
  padding: 5px 20px;
  margin: 0;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
  display: block;
  font-size: 14px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #737ae6;
}

.user-auto-search input {
  height: 36px !important;
  border-radius: initial !important;
  background-color: initial !important;
}
.user-auto-search {
  text-indent: 15px;
}
.user-auto-search .ant-select-selector {
  height: 36px !important;
  background-color: rgba(33, 33, 33, 0.06) !important;
  border-radius: 6px !important;
}
.supplier-select input {
  height: 36px !important;
  border-radius: initial !important;
  background-color: initial !important;
}

.supplier-select .ant-select-selector {
  height: 36px !important;
  background-color: rgba(33, 33, 33, 0.06) !important;
  border-radius: 6px !important;
}

.supplier-select .ant-select-selector .ant-select-selection-item,
.user-auto-search .ant-select-selector .ant-select-selection-item,
.supplier-select .ant-select-selector .ant-select-selection-placeholder,
.user-auto-search .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px !important;
}

.vmi-buyer-select .ant-select-selector {
  height: initial !important;
}

.load-search-select .ant-select-selector {
  height: initial !important;
  min-height: 36px !important;
}

.supplier-select .ant-select-selector .ant-select-selection-item {
  line-height: initial !important;
}

#BGrid .e-gridheader,
#AttachGrid .e-gridheader,
#NotificationGrid .e-gridheader {
  padding-right: 0px !important;
}

#ContactDetails .e-content {
  overflow-y: scroll !important;
}
#AttachGrid .e-content,
#NotificationGrid .e-content {
  overflow-y: auto !important;
}
#BGrid .e-content,
#AttachGrid .e-gridheader {
  overflow: auto !important;
}
#BGrid #BGridEditAlert {
  display: none;
}

#BGrid .e-dlg-container {
  display: none;
}
.business-unit-section {
  position: relative;
}

.business-unit-section .add-section {
  position: absolute;
  right: 0;
  top: -46px;
  background: #fff;
  padding-left: 30px;
}
.text-decoration {
  text-decoration: underline;
  cursor: pointer;
}

.location-details label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.035em;
  display: block;
  padding-bottom: 7px;
}
.location-details span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  display: block;
  margin-bottom: 15px;
}
.location-details p {
  background-color: rgba(33, 33, 33, 0.03);
  padding: 10px;
  border-radius: 5px;
}

.location-details p span {
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 13px;
}
.location-details p span b {
  padding-right: 5px;
}
.appointment-details {
  background-color: rgba(33, 33, 33, 0.03);
  padding: 10px;
  border-radius: 5px;
}
.appointment-details label {
  font-size: 12px;
}

.modify-partner-content {
  position: relative;
}
.modify-partner-content .save-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-left: 100px;
}

.btn-group-style {
  position: absolute;
  right: 0;
  top: -46px;
  padding-left: 50px;
  background-color: #fff;
}

.preAuth-options {
  padding: 15px;
  background: rgba(33, 33, 33, 0.06);
  border-radius: 4px;
  width: 138px;
  margin-top: 10px;
}
.preAuth-options span {
  display: block;
  padding-bottom: 10px;
}

.preAuth-options span:last-child {
  padding-bottom: 0px;
}

.general-options span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  font-family: "Rubik";
}

.general-options img {
  display: block;
  margin-bottom: 15px;
}
.general-status {
  margin-bottom: 15px;
}
.general-status span {
  font-size: 14px;
  font-weight: 600;
  font-family: "Rubik";
}

.client-section .ant-select-selector,
.modify-partner-details .ant-select-selector,
.add-item-master .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06) !important;
  border-radius: 6px !important;
  outline: none;
  font-weight: 400;
  height: 36px !important;
}

/* .contact-details-grid .e-content{
  overflow: auto !important;
} */
.add-trading-partner-grid .e-checkbox-wrapper {
  margin-left: -7px;
}
.add-trading-partner-grid .e-headercelldiv .e-checkbox-wrapper {
  margin-left: 0px;
}
.add-trading-partner-grid .e-gridheader {
  padding-right: 0 !important;
}

.added-trading-partner-grid .e-headercell {
  background: #cccccc !important;
  color: #1c1c1c !important;
}

.added-trading-partner-grid .e-gridheader {
  padding-right: 0 !important;
}

.business-unit-section .e-input-group {
  border: none !important;
  border-bottom: none !important;
  border-color: none !important;
  font-family: "Rubik" !important;
  font-size: 14px !important;
  margin-top: 5px;
}

.third-party-txt {
  margin-top: 10px;
  display: block;
  text-decoration: underline;
  cursor: pointer;
}

.failure {
  text-decoration: underline;
  cursor: pointer;
  color: red;
}

#acDocumentManagementID input {
  background-color: initial !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06);
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  height: 36px;
  align-items: center;
}

.address-label {
  font-family: "Rubik" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
  margin-bottom: 5px;
}

.po-summay-status {
  display: block;
  border-radius: 4px;
  padding: 6px 0;
  line-height: normal;
}

.po-status-label {
  font-family: "Rubik" !important;
  color: #1c1c1c;
  font-size: 15px !important;
  font-weight: 500 !important;
  /* padding-top: 8px; */
  display: inline-block;
  padding: 0px 20px;
  line-height: 33px;
  /* margin-top: 8px; */
  border-radius: 20px;
}
.po-draft {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    #f2ca52;
  color: #8c4d3f;
}
.po-submitted {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
  color: #79c24f;
}
.po-adj-ven {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #636799;
  color: #636799;
}
.po-adj-buy {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #737ae6;
  color: #737ae6;
}
.po-received {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6;
  color: #fff;
}
.po-part-received {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #737ae6;
  color: #fff;
}
.po-cancelled {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
  color: #8c4d3f;
}
.po-shipped {
  background: #79c24f;
  color: #fff;
}
.po-confirmed {
  background: #79c24f;
  color: #fff;
}
.po-receipt-adj {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
  color: #8c4d3f;
}
.po-rejected {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
  color: #8c4d3f;
}

.fillColor {
  background: #800080 !important;
  cursor: pointer !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.boldText {
  font-weight: 600 !important;
}
.removeColor {
  background: #fff;
}
.ant-tag {
  margin-bottom: 8px;
}

.fillColor input {
  color: #fff !important;
}

.lladmin-user-role .ant-btn-default {
  top: -46px !important;
}

.audit-log-section .ant-modal-body {
  padding-top: 10px;
}

.notfy-approve-btn {
  text-decoration: underline;
  /* display: block; */
}

.notfy-reject-btn {
  text-decoration: underline;
}

.po-create-template-section span {
  /* display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0; */
}

.po-create-template-section {
  display: inline-block;
}

.po-create-template-fields {
  /* display: inline-block; */
  float: left;
  width: 160px;
  padding: 0 7px;
}

.po-template-modal {
  min-width: auto;
}

.po-create-template-labels {
  float: left;
  width: 120px;
  padding: 0 7px;
}

.po-create-template-labels span {
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.po-template-modal .ant-modal-body {
  padding-top: 20px;
  min-height: 490px;
}

.po-template-modal .poHeaderfields {
  padding: 5px 0;
  display: block;
}

.changed-date-style {
  display: inline-block !important;
  padding: 2px 10px !important;
}

.btn-group {
  position: absolute !important;
  top: 12px;
  right: 0;
  background: #fff;
  padding-left: 20px !important;
}

.custom-lbl-style {
  font-family: "Rubik" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
  display: block;
}

.custom-value-style {
  font-family: "Rubik" !important;
  color: #1c1c1c;
  font-size: 15px !important;
  font-weight: 500 !important;
  padding-top: 8px;
  display: block;
  /* text-transform: uppercase; */
}

.additional-po-details .custom-lbl-style {
  font-family: "Rubik" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
  display: block;
}

.additional-po-details .custom-value-style {
  font-family: "Rubik" !important;
  color: #1c1c1c;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding-top: 8px;
  display: block;
  /* text-transform: uppercase; */
}

.numeric-input1 {
  line-height: 36px;
  display: block;
  padding-left: 5px;
  /* text-align: right; */
}

.numeric-input {
  border: none !important;
  outline: none !important;
}

.po-modal-more-details label {
  font-family: "Rubik" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
  display: block;
  padding-bottom: 3px;
}

.po-modal-more-details span {
  font-family: "Rubik" !important;
  color: #1c1c1c;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding-bottom: 10px;
  display: block;
}

.po-modal-more-details .e-headertext {
  color: #fff !important;
  padding: 0 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.po-cost-summary .item-section {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
}

.po-cost-summary span {
  font-family: "Rubik" !important;
  color: #1c1c1c;
  font-size: 15px !important;
  font-weight: 600 !important;
  display: block;
}

.po-notes {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  display: block;
}

.po-cost-summary label {
  font-family: "Rubik" !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 18px;
  color: #1c1c1c;
  display: block;
}

#notificationPopUp .e-content {
  overflow-y: scroll !important;
}

.rejectAll {
  color: #8c4d3f !important;
  border-color: #8c4d3f !important;
}

.approveAll {
  color: #79c24f !important;
  border-color: #79c24f !important;
}

.approveBtn,
.confirmBtn,
.statusBtn {
  margin: 7px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f !important;
  color: #79c24f !important;
  height: 24px !important;
  border: none;
}

.rejectBtn {
  height: 24px !important;
  border: none;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f !important;
  color: #8c4d3f !important;
}

.notification-widget .ant-modal-body {
  padding-top: 0px !important;
}

.po-lines-wrapper {
  width: inherit;
  overflow-x: auto;
  min-height: 150px;
  padding-bottom: 20px;
  border: 1px solid #dadada;
}

.po-charges .ant-select-selector {
  padding-left: 0px !important;
}

.po-line-tbl {
  table-layout: fixed;
}

.po-lines-wrapper {
  overflow: auto;
}
.profile-image {
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-top: 30px;
}
.po-hyper-link {
  color: #737ae6 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.apply-row-color {
  background-color: #d1d15f;
}

#poLinesCarrier .e-headercell {
  display: none;
}

#poLinesCarrierItems .e-headercell {
  display: table-cell !important;
}

.custom-pagination {
  height: 46px;
  background: #f2f2f2;
  border-color: #e0e0e0;
  color: #000;
  opacity: 0.87;
  border-style: solid;
  border-width: 1px;
  display: flex;
  font-size: 13px;
  padding: 0 13px;
  white-space: normal;
  width: 100%;
  font-family: "Roboto";
  justify-content: space-between;
  line-height: 44px;
}

.template-type-style
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06);
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  height: inherit !important;
  align-items: center;
}

.poHeaderfields {
  position: relative;
}

.template-type-style {
  position: absolute;
  top: 0;
  right: 0;
}

.ant-select-multiple .ant-select-selection-item {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}

.applyShipColor {
  /* color: red; */
  display: block;
  background-color: #d1d15f;
  /* height: 39px; */
  display: block;
  border-radius: 4px;
  padding: 6px 0;
  line-height: normal;
  text-align: center;
}

/* bold report style for parameter dropdown */
.e-ddl-popup div > ul li,
.e-ddl-popup div > .e-listbox li {
  padding: 8px 5px 8px 1.25em !important;
}
