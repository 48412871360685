.LMLoadDetailsPrintButton {
  border-color: #737ae6 !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff !important;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 4px;
  float: right;
  height: 36px;
  width: 110px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
}

.LMLoadDetailsPrintButtonCR {
  border-color: #737ae6 !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff !important;
  margin-top: 0px;
  margin-left: 1rem;
  border-radius: 4px;
  float: right;
  height: 36px;
  width: 110px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
}

.LMLoadDetailsPrintButtonPrintLD {
  border-color: #737ae6 !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff !important;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 4px;
  height: 36px;
  width: 110px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
}

.LMLoadDetailsPrintButtonLD {
  border-color: #737ae6 !important;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 4px;
  height: 36px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 800;
  margin-right: 0.4rem;
  color: #565ecd !important;
}

.LMLoadDetailsRejecttButtonLD {
  border-color: #737ae6 !important;
  margin-top: 0px;
  /* margin-left: 1rem; */
  border-radius: 4px;
  height: 36px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 800;
  margin-right: 0.4rem;
  color: #565ecd !important;
}

.LMLoadDetailsLineWidth {
  width: calc(100% - 365px);
  float: left;
}

.PLMLoadDetailsPrintButtonbuttonwidth {
  float: right;
  width: 130px;
}

.lmLoadDetailsFormlabel {
  font-family: Rubik;
  color: #737ae6 !important;
  border: none;
  background-color: transparent;
  float: left;
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
}

.lodDetailsFormLabel {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
}

.LMLoadDetailsAttachmentLineWidth {
  width: calc(100% - 340px);
  float: left;
  margin-bottom: 5px;
}

.PLMLoadDetailsAttachmentButtonbuttonwidth {
  width: 340px;
  position: relative;
  top: 4px;
  padding-left: 1.3rem;
}

.LMLoadDetailsAddAttachmentButton {
  border: 1px solid #737ae6 !important;
  color: #737ae6 !important;
  margin-top: 20px;
  background-color: white !important;
  border-radius: 4px;
  margin-left: 0px;
  height: 36px;
  font-family: Rubik;
  font-weight: 600;
}

.LMLoadDetailsAddAttachmentButton:hover {
  background-color: white !important;
}

.notesButton {
  border: 1px solid white;
  background-color: white;
}

.load-summary-box {
  margin-left: 10px;
}

.text-field-summary .ant-col.ant-form-item-label {
  width: 110px;
  text-align: left;
}

.text-field-summary-total.ant-col.ant-form-item-label {
  width: 110px;
  text-align: left;
  margin-top: 60px;
}

.ant-row.ant-form-item.text-field-summary-total {
  margin-top: 65px;
}

.poLinesTbl {
  background-color: #e4f3dc;
}

tr.poLinesTblTR td {
  padding: 3px;
  text-align: center;
  font-size: 11px;
}

.itemCodeCls {
  font-size: 11px;
  color: #737ae6;
  text-decoration: underline;
  cursor: pointer;
}

.itemCodeClsVendor {
  font-size: 12px;
  color: #737ae6;
  text-decoration: underline;
  cursor: pointer;
}

.primary-title-loadViewSummary {
  font-family: WorkSans;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #1c1c1c;
  margin: 0px;
  border-bottom: 1px solid #cccccc;
  margin-top: 20rem;
}

.lblbold {
  font-weight: bold;
}

.btnSaveAttach {
  margin-left: 0.8rem;
}

.lblLoadNumber {
  border: 1px solid #a3a3a3;
  cursor: pointer;
}

.lblText {
  font-size: 15px;
  color: red;
}

tr.receiveTblTr td {
  text-align: center;
  padding-top: 0.5rem;
  font-size: 13px;
}

.loaderClass {
  height: 5rem;
}

.loadSumryForm {
  border-top: 1px solid #cccccc;
}

.loadSumHeading {
  font-size: 18px;
  margin-bottom: 0;
  padding-bottom: 0.6rem;
}

.arrivalDateSpan {
  cursor: pointer;
}

.loadNumEmpty {
  height: 2rem;
  width: 12rem;
}

.clsfList {
  width: 6rem;
}

.notes-load-modal .ant-modal-body {
  padding-top: 5px;
}

.notes-form-load .ant-form-item {
  margin-bottom: 0;
}

tr.disabledAttach {
  display: none;
}

.arrivFormCls {
  margin-top: 0.6rem;
}