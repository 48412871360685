.primary-title {
  font-family: WorkSans;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #1c1c1c;
  margin: 0px;
  border-bottom: 1px solid #cccccc;
}
.secondary-title {
  font-family: WorkSans;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #1c1c1c;
  margin: 10px 0px;
  border-bottom: 1px solid #cccccc;
}
.custom-tbl-style,
.custom-tbl-style td,
.custom-tbl-style th {
  /* border: 1px solid #ddd; */
  text-align: left;
}

.custom-tbl-style th {
  /* border: 1px solid #ddd; */
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6;
  color: #fff;
  padding: 10px 8px;
  font-family: "WorkSans";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;
}
.table > :not(:first-child) {
  border-top: 1px solid #ddd;
}

.custom-tbl-style {
  border-collapse: collapse;
  width: 100%;
}

.custom-tbl-style .e-input-group {
  border: none !important;
  border-bottom: none !important;
  border-color: none !important;
  font-family: "Rubik" !important;
  font-size: 14px !important;
  margin-top: 5px;
}

.custom-tbl-style img {
  height: 35px;
  margin: 0 auto;
  display: block;
}
.custom-tbl-style .ant-radio-group {
  margin-top: 5px;
}

.custom-tbl-style td {
  padding: 0 10px;
}

.caption {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;
}

.menu-card-box {
  width: 262.2px;
  height: 312px;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 4px;
  padding: 20px;
  border-left: 6px solid transparent;
  border-top-left-radius: 0px;
  text-align: center;
  border-bottom-left-radius: 0px;
  display: inline-table;
  margin-top: 20px;
  margin-right: 30px;
  cursor: pointer;
}

.menu-card-box h6 {
  font-family: WorkSans;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.0015em;
  word-break: break-word;
}

.menu-card-box p {
  font-family: Rubik;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #1c1c1c;
}

.trading-card-box-style,
.reports-box-style:nth-child(n-1) {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    linear-gradient(146.21deg, #f2ca52 1.08%, rgba(242, 202, 82, 0) 78.01%);
  border-left: 6px solid #f2ca52;
}

.trading-card-box-style h6,
.reports-box-style:nth-child(n-1) h6 {
  color: #f2ca52;
}

.item-master-card-style,
.reports-box-style:nth-child(2n-1) {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    linear-gradient(142.56deg, #8c4d3f 3.67%, rgba(140, 77, 63, 0) 75.92%);
  border-left: 6px solid #8c4d3f;
}

.item-master-card-style h6,
.reports-box-style:nth-child(2n-1) h6 {
  color: #8c4d3f;
}
.business-grotp-box-style {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    linear-gradient(146.21deg, #f2ca52 1.08%, rgba(242, 202, 82, 0) 78.01%);
  border-left: 6px solid #f2ca52;
}

.business-grotp-box-style h6 {
  color: #f2ca52;
}

.admin-box-style {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    linear-gradient(142.56deg, #8c4d3f 3.67%, rgba(140, 77, 63, 0) 75.92%);
  border-left: 6px solid #8c4d3f;
}

.admin-box-style h6 {
  color: #8c4d3f;
}
.application-box-style,
.reports-box-style:nth-child(3n-1) {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    linear-gradient(142.71deg, #79c24f 3.84%, rgba(121, 194, 79, 0) 68.01%);

  border-left: 6px solid #79c24f;
}

.application-box-style h6,
.reports-box-style:nth-child(3n-1) h6 {
  color: #79c24f;
}
.looksup-box-style,
.reports-box-style:nth-child(4n-1) {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    linear-gradient(143.38deg, #737ae6 2.45%, rgba(115, 122, 230, 0) 72.01%);

  border-left: 6px solid #737ae6;
}

.looksup-box-style h6,
.reports-box-style:nth-child(4n-1) h6 {
  color: #737ae6;
}
.user-preference-box-style,
.reports-box-style:nth-child(5n-1) {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    linear-gradient(143.85deg, #636799 3.47%, rgba(99, 103, 153, 0) 68.32%);

  border-left: 6px solid #636799;
}

.user-preference-box-style h6,
.reports-box-style:nth-child(5n-1) h6 {
  color: #636799;
}

.reports-box-style:nth-child(6n-1) {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    linear-gradient(143.85deg, #adba2f 3.47%, rgba(99, 103, 153, 0) 68.32%);

  border-left: 6px solid #adba2f;
}

.reports-box-style:nth-child(6n-1) h6 {
  color: #adba2f;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #161617;
}
.ant-radio-inner::after {
  background-color: #3f4245;
}
.ant-radio-input:hover {
  background-color: #3f4245;
}
.ant-checkbox-inner {
  border: 1px solid #161617;
}
.e-pagerconstant {
  float: left;
  display: none;
}
span.e-constant {
  display: none;
}
.e-lib.e-pager .e-pagerdropdown {
  display: inline-block;
  height: 32px;
  margin-left: 12px;
  margin-right: 0;
  margin-top: 6px;
  overflow: hidden;
  vertical-align: middle;
  width: 90px;
}
span.e-input-group.e-control-wrapper.e-alldrop.e-ddl.e-lib.e-keyboard.e-valid-input {
  border: 1px solid #b1b1b1;
  border-radius: 5px;
  padding-left: 8px;
  width: 80% !important;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background-color: #b1b1b1;
}
.e-gridpager.e-control.e-pager.e-lib {
  background: #f2f2f2;
}
.e-lib.e-pager .e-pagercontainer {
  background-color: #f2f2f2;
  border-color: #e0e0e0;
}
.e-lib.e-pager .e-pagerconstant:after {
  visibility: visible;
  content: "Rows per page";
  margin-top: 0.8rem;
  overflow: hidden;
  width: auto;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #1c1c1c;
  float: left !important;
  display: flex;
  justify-content: left !important;
}
.e-pager .e-pagercontainer {
  float: right;
}

.grid-config-header {
  padding: 4px;
  margin-bottom: 12px;
}

.heading-show-hide {
  background: #f2f2f2;
  padding: 4px;

  margin-bottom: 12px;
}
.heading-saved {
  background: #f2f2f2;
  padding: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.heading-saved h6 {
  font-family: "Rubik" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
  padding-top: 5px;
}
.heading-show-hide h6 {
  font-family: "Rubik" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
  padding-top: 5px;
}
.text-checkbox-hideshow label.ant-checkbox-wrapper.ant-checkbox-group-item {
  width: 100%;
  padding: 6px;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
}
.text-checkbox-hideshow button.ant-btn.ant-btn-default {
  text-align: center;
  width: 65%;
  margin-left: 15%;
}
.text-checkbox-hideshow .ant-checkbox-group {
  overflow-y: scroll;
  height: 135px;
  overflow-x: hidden;
}
.sidebar button.ant-btn.ant-btn-default {
  text-align: center;
  width: 65%;
  margin-left: 15%;
}
.ant-drawer-body {
  padding-top: 10px;
}
.ant-modal-header {
  padding: 15px 4px 2px 21px;
}
.ant-modal-header-title {
  font-family: WorkSans;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #1c1c1c;
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #737ae6 !important;
  z-index: 1;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #737ae6;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab:hover {
  color: #111111;
}
.e-grid th.e-headercell[aria-sort="ascending"] .e-headertext,
.e-grid th.e-headercell[aria-sort="descending"] .e-headertext,
.e-grid th.e-headercell[aria-sort="ascending"] .e-sortfilterdiv,
.e-grid th.e-headercell[aria-sort="descending"] .e-sortfilterdiv {
  color: #fff;
  opacity: 1;
}
.e-lib.e-grid
  .e-gridheader
  .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow) {
  color: #ffffff;
  padding: 7px;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  border-bottom: 1px solid #cccccc;
  width: 95%;
  padding: 8px;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
  padding: 6px;
}
.brdCmb-home {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.0025em;
  color: #737ae6;
  float: left;
  text-transform: capitalize;
  margin: 1px 6px 4px 1px;
  position: relative;
  left: 30px;
}
.brdCmb-home a.disabled {
  text-decoration: none;
  pointer-events: none;
  font-weight: 500;
  color: rgba(28, 28, 28, 1);
}
.invoice-summary-header {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  padding-top: 12px;
}
.invoice-summary-value {
  padding-top: 12px;
}
.billing-invoice-summary-value {
  text-align: right;
}
.numeric-input-value {
  text-align: center !important;
  border: none;
}
.user-auto-search .ant-select-selector {
  background-image: url(../assets/icons/common/search.svg);
  background-repeat: no-repeat;
  background-position: 3% 49%;
}
.user-auto-search span.ant-select-selection-placeholder {
  margin: 0px 22px;
}
.ant-form-item-explain-warning {
  color: #ca3639 !important;
}
