.POCreationWidth {
  width: 64%;
  float: left;
}

.CreatePObuttonsTab {
  width: 36%;
  float: right;
}

.PlainButtons {
  border-color: #737ae6 !important;
  color: #737ae6 !important;
  margin-top: 0px;
  border-radius: 4px;
  margin-left: 8px;
  height: 36px;
  font-family: Rubik;
  font-weight: 600;
}

.PlainButtons:hover {
  background-color: white !important;
}

.CreatePoButton {
  border-color: #737ae6 !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff !important;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 4px;
  float: right;
  height: 36px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
}

.POHeaderwidth {
  width: 100%;
  font-family: Work Sans;
  font-size: 17px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.0015em;
  text-align: left;
}

.CopytoNewPO {
  border-color: #737ae6 !important;
  color: #737ae6 !important;
  margin-top: 0px;
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  font-family: Rubik;
  font-weight: 600;
  margin-top: 25px;
}

.arrowVisibleButton {
  border: 1px solid #737ae6 !important;
  color: #737ae6 !important;
  margin-top: 0px;
  border-radius: 4px;
  margin-right: 3px;
  height: 36px;
  font-family: Rubik;
  font-weight: 600;
  margin-top: 5px;
  float: right;
  background: transparent;
}

.CopytoNewPO:hover {
  background-color: #f2f2f2 !important;
}

.TabStyle {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 10px 15px;
  padding-bottom: 0;
  margin-bottom: 15px;
}

.TabStyle1 {
  width: 50%;
  margin-left: auto;
  padding: initial;
}

.TabStyle2 {
  width: 50%;
  padding: initial;
}

.poHeaderfields
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06);
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  height: 36px;
  align-items: center;
}

.poHeaderfields1
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06);
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  height: 36px;
  align-items: center;
  color: black;
}

.fieldlablestyle {
  font-family: Rubik;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: left;
}

.boxfieldstyle {
  height: 36px;
  left: 0px;
  top: 0px;
  border-radius: 6px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-left: 5px;
  background-color: rgba(33, 33, 33, 0.06);
  border-color: white;
}

.POlineswidth {
  width: 81%;
}

.polineButtonswidth {
  width: 19%;
}

.poHeaderDeleteButton {
  color: #8c4d3f !important;
  border: 1px solid #8c4d3f !important;
  height: 36px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.0125em;
  text-align: center;
}

.poHeaderAddLineButton {
  border-color: #737ae6 !important;
  color: #737ae6 !important;
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  font-family: Rubik;
  font-weight: 600;
  font-size: 14px;
}

.poHeaderAddLineButton:hover {
  background-color: white !important;
}

.transConfirmCheckBox {
  height: 10px;
  left: 13px;
  top: 19px;
  border-radius: 1px;
}

.poNotesImage {
  height: 30px;
  width: 30px;
  margin-left: 5px;
}

.chargesModalCloseButton {
  border-color: #737ae6 !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff !important;
  margin-top: 0px;
  margin-left: 10px;
  border-radius: 4px;
  margin-top: 20px;
  height: 36px;
  width: 105px;
  float: right;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
}

.chargesModalDropdown {
  height: 36px;
  position: relative;
  border-radius: 3px;
  font-family: Rubik;
}

.chargesModalDropdownField {
  background-color: transparent;
  height: 36px;
  color: #000;
  outline: none;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.save-template-modal
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: rgba(33, 33, 33, 0.06);
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  height: 36px;
}

#poHeader .e-pager {
  background-color: #f2f2f2 !important;
}

#poHeader .e-pagercontainer {
  background-color: #f2f2f2 !important;
}

.polistsTabWidth {
  width: 80%;
}

.PoSearchFieldsTabsStyle {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 10px 15px;
  padding-bottom: 0px;
}

.SearchPObutton {
  border-color: #737ae6 !important;
  color: #737ae6 !important;
  margin-top: 0px;
  border-radius: 4px;
  margin-left: 0px;
  width: 90%;
  height: 36px;
  font-family: Rubik;
  font-weight: 600;
  margin-top: 25px;
}

.SearchPObutton:hover {
  background-color: #f2f2f2 !important;
}

.polistsTabWidth {
  width: 73%;
}

.polistsbuttonsWidth {
  width: 27%;
  margin-top: 25px;
}

.POlistsDownloadButton {
  border-color: #737ae6 !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: white !important;
  margin-top: 0px;
  border-radius: 4px;
  margin-left: 0px;
  height: 36px;
  float: right;
  font-family: Rubik;
  font-weight: 500;
}

.notesButton {
  border: 1px solid white;
  background-color: white;
}

.poTablelabelButton {
  font-family: "WorkSans";
  color: #737ae6 !important;
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
}

.poNotesButton {
  font-family: Rubik;
  color: #737ae6 !important;
  border: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.btn-group {
  position: absolute;
  top: 12px;
  right: 0;
  background: #fff;
  padding-left: 20px;
}

.btn-group .ant-btn {
  margin-left: 20px;
}

.po-hyper-link {
  color: #737ae6 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.numeric-input {
  border: none !important;
  outline: none !important;
}

.po-modal-more-details label {
  font-family: "Rubik" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #1c1c1c;
  display: block;
  padding-bottom: 3px;
}

.po-modal-more-details span {
  font-family: "Rubik" !important;
  color: #1c1c1c;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding-bottom: 10px;
  display: block;
}

.po-more-details-body .ant-modal-body {
  padding-top: 10px;
}

.vendorPriceListModalCloseButton {
  border-color: #737ae6 !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff !important;
  margin-top: 0px;
  width: 100px;
  margin-left: 10px;
  border-radius: 4px;
  margin-top: 15px;
  height: 36px;
  float: right;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
}

.uploadModalvendorTemplate {
  color: #737ae6;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 20px;
  margin-top: 40px;
  display: inline-block;
  margin-right: 25px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
}

.item-search .ant-input-search-button {
  border: none;
}

.copy-move-lbls span {
  display: flex;
  height: 36px;
  align-items: center;
  margin-bottom: 10px;
}

.copy-move-modal .ant-modal-body {
  padding-top: 0px;
}

.copy-po-btn-group {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.copy-po-btn-group .ant-btn {
  margin-left: 20px;
}

.copy-move-modal .ant-picker-input > input[disabled] {
  color: initial !important;
}

.copy-move-modal .ant-form-item-with-help .ant-form-item-explain {
  min-height: 0px !important;
}

.copy-move-modal
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: initial !important;
}

.copy-move-modal .left-arrow-copy {
  position: absolute;
  top: 54px;
  left: -20px;
  cursor: pointer;
  font-size: 20px;
}

.copy-move-modal .right-arrow-copy {
  position: absolute;
  right: -20px;
  top: 54px;
  cursor: pointer;
  font-size: 20px;
}

.POModifySummaryHeaderfields {
  position: relative;
}

.custom-tbl-style .ant-picker {
  border: none;
}

.rejectAll {
  color: #8c4d3f !important;
  border-color: #8c4d3f !important;
}

.approveAll {
  color: #79c24f !important;
  border-color: #79c24f !important;
}

#DemandPOGrid .e-gridheader {
  padding-right: 0px !important;
}

#DemandPOGrid .e-disabled {
  opacity: .70;
}

#DemandPOGrid .editableCell {
  background : rgb(229 232 226);
  cursor: text;
}

.advCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.advCheckbox .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%,-50%);
  opacity: 1;
  transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
  content: " ";
}
